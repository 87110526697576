import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

<input 
type='hidden' 
name='form-name'
value='contactForm' />
    
    class Contact extends Component {
        constructor(props){
           super(props);
      
           this.state = {
               fields: {},
               errors: {}
           }
        }
        handleChange(field, e){         
            let fields = this.state.fields;
            fields[field] = e.target.value;        
            this.setState({fields});
        }
    
        render(){
            return (

            <section id="contact">

                     <div className="row">
                     <div className="two columns header-col">

                        <h1><span>Get In Touch.</span></h1>

                     </div>
                     </div>
                 <div className="ten columns flex-container"></div>

                <div>           
                   <form name="contact" method="POST" action= "/contact"  className="contactForm" data-netlify="true">
                        <div className="col-md-6">
                           
                         
                          <fieldset  className="Form1">
                            <input type="hidden" name="form-name" value= "contact"/>
                          <label htmlFor="contactName">Name<span className="required">*</span></label> 
                             <input name="name" type="text" size="30" placeholder="" maxlength ="30" />
                              <br/>
                            <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                             <input name="email" type="email" size="30" placeholder="" maxlength ="30" />
                              <br/>
                              <label htmlFor="contactSubject">Subject<span className="required">*</span></label>
                              <input name="subject" type="text" size="30" placeholder="" maxlength ="30" />
                               <br/>
                               <label htmlFor="contactMessage">Message<span className="required">*</span></label>
                              <textarea name="message" type="text" size="30" placeholder="" maxlength ="800" cols="50" rows="15"></textarea>
                               <br/>
                               <label htmlFor="contactMessage"></label>
                             <button type="submit" className="button1">Send Message</button>
                             
                         </fieldset>
                         
                      </div>
                    </form>
                </div>
                
           
            </section>
          )
        }
    }


export default Contact
