import React, { Component } from 'react';
import './Resume.css'



class Resume extends Component {
  render() {

    if(this.props.data){
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p>{work.description}</p>
        </div>
      })
      var skills = this.props.data.skills.map(function(skills){
        var className = 'bar-expand '+skills.name.toLowerCase();
        return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (

      <section id="skills">
      <section id="resume">



      <div className="row skill">
      <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>
      <div className="logo">
          <img src="/images/portfolio/ASPMVC.jpg" alt=""/>
          <img src="/images/portfolio/ASPWebforms.jpg" alt=""/>
          <img src="/images/portfolio/Cplusplus.jpg" alt=""/>
          <img src="/images/portfolio/CRM.jpg" alt=""/>
          <img src="/images/portfolio/CSharp.jpg" alt=""/>
          <img src="/images/portfolio/CSS.jpg" alt=""/>
          <img src="/images/portfolio/Git.jpg" alt=""/>
          <img src="/images/portfolio/HTML.jpg" alt=""/>
          <img src="/images/portfolio/Jquery.jpg" alt=""/>
          <img src="/images/portfolio/JS.jpg" alt=""/>
          <img src="/images/portfolio/MSSQL.jpg" alt=""/>
          <img src="/images/portfolio/MySQL.jpg" alt=""/>
          <img src="/images/portfolio/NoSQL.jpg" alt=""/>
          <img src="/images/portfolio/Python.jpg" alt=""/>
          <img src="/images/portfolio/React.jpg" alt=""/>
          <img src="/images/portfolio/VSCODE.jpg" alt=""/>
          <img src="/images/portfolio/TSQL.jpg" alt=""/>
          <img src="/images/portfolio/VBA.jpg" alt=""/>
          <img src="/images/portfolio/VisualStudio.jpg" alt=""/>
          <img src="/images/portfolio/VisualBasic.jpg" alt=""/>
       
          </div>
         

         <div className="nine columns main-col">

            <p>
            </p>

				<div className="bars">
				   <ul className="skills">
					</ul>
 
				</div>
			</div>
      </div>
   </section>
   </section>
    );
  }
}

export default Resume;
